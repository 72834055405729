<template>
	<div class="p_20 indexBanner">
		<el-dialog :visible.sync="isShow" title="账号组管理" :close-on-click-modal="false" @close="closeDialog">
			<!-- <el-divider></el-divider> -->
			<el-form :model="form" label-width="80px">
				
				
				<el-form-item label="组名">
					<el-input v-model="form.name" class="w_400"></el-input>
				</el-form-item>
				<el-form-item label="账户类型">
					<el-select v-model="form.account_type_id" @change="getClient">
						<el-option 
							v-for="item in opt"
							:key="item.id"
							:value="item.id"
							:label="item.name"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="客户" v-if="form.account_type_id != 1">
					<el-select v-model="form.client_id">
						<el-option 
							v-for="item in opt1"
							:key="item.id"
							:value="item.id"
							:label="item.name"
						></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeDialog">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isShow: false,
				opt:[],
				opt1:[],
				form: {
					id:'',
					name:'',
					account_type_id:'',
					client_id:''
				},
				time: '',

				//图片上传
				imageUrl: '',
				// dialogVisible: false,
				// disabled: false
			}
		},
		mounted(){
			this.getType();
		},
		methods: {

			

			getType(){
				this.axiosGets('Public/accountTypeList',{}, res => {
					// console.log(res);
					if(res.data.code === 200) {
						this.opt = res.data.data;
					}
					
				})
			},

			getClient(val){
				// console.log(val);
				this.form.client_id = '';
				if(val != 1) {
					let  params = {
						account_type_id:val,
						page: 1,
						limit: 10000000
					}
					
					this.axiosPost("Client/clientList", params, function(res) {
						// console.log(res);
						if(res.data.code === 200) {
							this.opt1 = res.data.data.data;
						}
					})
				}
			},

			//提交
			submit() {
				console.log(this.form);
				let params = this.form;

				if (this.form.id) {
					this.edit(params);
				} else {
					this.add(params)
				}
			},


			//新增
			add(params) {
				this.axiosPost('Group/add', params , res => {
					if(res.data.code === 200) {
						this.msgSuccess(res.data.message);
						this.$parent.getTableData();
						this.isShow = false;
					}
				})
			},

			//编辑
			edit(params) {
				this.axiosPost('Group/editor', params , res => {
					if(res.data.code === 200) {
						this.msgSuccess(res.data.message);
						this.$parent.getTableData();
						this.isShow = false;
					}
				})
			},


			
			//关闭窗口 清空数据
			closeDialog() {
				this.form = {
					id:'',
					name:'',
					account_type_id:''
				},
				
				this.isShow = false;
			}
		}
	}
</script>

<style scoped>

</style>
